<template>
  <div class="content-search-transation-detail">
    <div class="content-text-title">
      <v-icon class="global-icon-arrow-back" @click="back()"
        >mdi-arrow-left</v-icon
      >
      <p class="text-title">Detalle de: {{ sNameDetail }}</p>
    </div>
    <v-spacer />
    <div v-if="bShowBtnDetailOperation" class="content-btn-detail-operation">
      <v-btn
        @click="setDetailOperation()"
        class="btn-add-global global-btn-primary"
      >
        Detalle de operación
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    oItem: Object,
  },
  data() {
    return {
      bShowBtnDetailOperation: false,
    };
  },
  beforeMount() {
    this.bShowBtnDetailOperation = this.oItem.oOrder.sOrderId ? true : false;
  },
  methods: {
    back() {
      this.$router
        .push({
          name: "AccountDetail",
          params: { id: this.$route.params.id },
        })
        .catch(() => {});
    },
    setDetailOperation() {
      this.$router
        .push(
          `/home/account-detail/${this.$route.params.id}/transation/${this.$route.params.idTransation}/op-detail-transition-detial-account/${this.oItem.oOrder.sOrderId}`
        )
        .catch(() => {});
    },
  },
  computed: {
    sNameDetail() {
      return this.$store.state.sNameDetail;
    },
  },
  watch: {

  },
};
</script>
<style scoped>
.content-search-transation-detail {
  display: flex;
  align-content: center;
  width: 100%;
}
.content-text-title {
  display: flex;
}
.text-title {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 2rem;
  margin-bottom: 0px;
  margin-right: 5px;
}

.content-btn-detail-operation {
  width: 200px;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-search-transation-detail {
    display: block;
    align-content: center;
    width: 100%;
  }
  .content-text-title {
    display: flex;
  }
  .text-title {
    color: var(--primary-color-text);
    font-family: "pop-SemiBold";
    font-size: 2rem;
    margin-bottom: 0px;
    margin-right: 5px;
  }

  .content-btn-detail-operation {
    width: 100%;
    margin-top: 20px;
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}
/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
